.form-title {
  font-size:16px;
  font-weight:500;
  margin-bottom:0.5rem;
  line-height:1.3;
}

.inset-list {
  list-style:none;
  padding-left:40px;
  line-height:1.3;

  li {
    position:relative;

    &:after {
      content:"";
      width:0;
      height:0;
      border-left: 18px solid $primary;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      position:absolute;
      left:-40px;
      top:0;
    }
  }
}

.btn {
  font-weight:bold;
}

label.required:after {
  content:" *";
  color:$red;
}

.form-control {
  font-size:16px;
}

.form-field-wrapper {
  margin-bottom: 1em;
  label {
    display:block;
  }
}

@include media-breakpoint-up(md) {
  .likert{
    display: flex;
    gap: 3em;
  }
}