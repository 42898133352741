html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  color:$black;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", serif;
}

header {
  padding: 30px 0;
  background: $white;
  position: relative;
  flex: 0;

  .logo {
    max-width: 300px;
  }
}

.main {
  min-height: calc(100vh - 178px);
  position: relative;
}

footer {
  box-shadow: inset 0px 3px 9px 0px $grey;
  background-color: #ebebeb;
  padding: 20px 0;
  text-align: center;
  color: #001e33;
  font-weight: 300;
}

.auth-message {
  padding: 20px;
  font-size: 1.5rem;
  color: $white;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .auth-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.cls-3 {
  fill:#1ef3fd;
}

.cls-4 {
  fill:#44596c;
}

.alert-warning {
  color:$black;
  background-color:$grey;
  border-color:$gold;
}