@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-Thin.ttf") format("truetype");
  font-style:normal;
  font-weight:100;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-ThinItalic.ttf") format("truetype");
  font-style:italic;
  font-weight:100;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-ExtraLight.ttf") format("truetype");
  font-style:normal;
  font-weight:200;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-style:italic;
  font-weight:200;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-Light.ttf") format("truetype");
  font-style:normal;
  font-weight:300;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-LightItalic.ttf") format("truetype");
  font-style:italic;
  font-weight:300;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-Regular.ttf") format("truetype");
  font-style:normal;
  font-weight:400;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-Medium.ttf") format("truetype");
  font-style:normal;
  font-weight:500;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-MediumItalic.ttf") format("truetype");
  font-style:italic;
  font-weight:500;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-SemiBold.ttf") format("truetype");
  font-style:normal;
  font-weight:600;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-style:italic;
  font-weight:600;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-Bold.ttf") format("truetype");
  font-style:normal;
  font-weight:700;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-BoldItalic.ttf") format("truetype");
  font-style:italic;
  font-weight:700;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-style:normal;
  font-weight:800;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-style:italic;
  font-weight:800;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-Black.ttf") format("truetype");
  font-style:normal;
  font-weight:900;
}
@font-face {
  font-family: 'Poppins';
  src: url("../../fonts/Poppins-BlackItalic.ttf") format("truetype");
  font-style:italic;
  font-weight:900;
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-underline.text-strikethrough {
  text-decoration: underline line-through;
}

.text-superscript,
.text-subscript {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

.text-subscript {
  bottom: -0.25em;
}

.text-superscript {
  top: -0.5em;
}

.text-baseline {
  vertical-align: baseline;
}

a {
  text-decoration:underline;

  &:hover, &:focus {
    color:$secondary;
  }
}

.fsx2 {
  font-size: 2rem;
}
.fs85 {
  font-size: 85%;
}