$gold: #CF9D2B;

$blue: #246CB3;

$green: #66BB66;

$red: #FF0000;

$grey: #BBBBBB;

$dark-blue:#12365A;

$grey-disabled: #999999;

$primary: $gold;
$secondary: $blue;
$light: $grey;
$dark: $dark-blue;
$white: #ffffff;
$black: #000;

$border-primary: $blue;

$input-placeholder: $blue;

$font-size-base: 14;
$input-color: $primary;
$input-border-color: $blue;
$input-border-radius: 0;
$btn-border-radius: 0;

$border-radius: 0;