// Override default variables before the import
@import "vars.scss";

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import "layout.scss";
@import "components/buttons.scss";
@import "components/modal.scss";
@import "components/loading.scss";
@import "components/form.scss";
@import "components/toast.scss";
@import "components/progress.scss";
@import "components/font.scss";
